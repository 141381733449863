import { Route } from "react-router-dom";
import Idcard from "../pages/landing/idcard";
import ProfileSettings from "../../core/settings/";
import Signup from "../../public/signup";
const CustomPrivateRoute = () => [
  <Route key="id-card" path="/my-id-card/:event/:slug" element={<Idcard />} />, // Add the custom redirect route here
  <Route key="profile" path="/profile-settings" element={<ProfileSettings />} />,
  <Route key="sign-up" path="/sign-up" element={<Signup hideMenu={true} hideHeader={true} />} />,
];
export default CustomPrivateRoute;
