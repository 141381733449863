import { Route } from "react-router-dom";
import EventsLanding from "../pages/landing/eventsLanding";
import EventDetailLanding from "../pages/landing/eventDetailLanding";
import Idcard from "../pages/landing/idcard";
import React from "react";
import Luckydraw from "../pages/landing/luckydraw";
import Token from "../pages/landing/token";
import Campaign from "../pages/landing/campaign";
import Embed from "../pages/landing/event/register/embed";
import Signup from "../../public/signup";

const CustomPublicRoute = () => [
  <Route key="events" path="/events" element={<EventsLanding></EventsLanding>} />,
  <Route key="embed" path="/embed/:slug" element={<Embed></Embed>} />,
  <Route key="lucky-draw" path="/lucky-draw" element={<Luckydraw></Luckydraw>} />,
  <Route key="event-details" path="/events/:slug" element={<EventDetailLanding />} />,
  <Route key="id-card" path="/my-id-card/:event/:slug" element={<Idcard />} />, // Add the custom redirect route here
  <Route key="campaign" path="/campaign/:slug" element={<Campaign />} />,
  <Route key="sign-up" path="/sign-up" element={<Signup />} />,
  <Route key="purchase-plan" path="/purchase-plan" element={<Signup hideMenu={true} hideHeader={true} />} />,
  <Route key="get-tokent" path="/get-token" element={<Token />} />, // Add the custom redirect route here
];
export default CustomPublicRoute;
