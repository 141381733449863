import { useEffect } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { useLocation } from "react-router-dom";
export function AmplitudePageTracker() {
  const location = useLocation();

  // Initialize Amplitude once
  useEffect(() => {
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY || "YOUR-API-KEY");
  }, []);

  // Track page views whenever location changes
  useEffect(() => {
    amplitude.track("page_view", {
      path: location.pathname,
      url: window.location.href,
      title: document.title,
      timestamp: new Date().toISOString(),
    });
  }, [location]);

  return null;
}
