import { useTranslation } from "react-i18next";
import FormInput from "../input";
import { Content, Footer, Overlay, Page } from "./styles";
import { useEffect } from "react";
import { GetIcon } from "../../../icons";

const Message = (props) => {
  const closeModal = async () => {
    try {
      if (typeof props.message.onClose === "function") {
        await props.message.onClose().then((status) => {
          (status ?? true) && props.closeMessage();
        });
      } else {
        props.closeMessage();
      }
    } catch (error) {
      console.error("Error in onClose:", error);
    }
  };

  useEffect(() => {
    if (props.message.type === 1) {
      const timer = setTimeout(() => {
        typeof props.message.onClose === "function" && props.message.onClose();
        props.closeMessage();
      }, 6000);
      return () => clearTimeout(timer);
    }
  }, [props]);
  const proceedAction = async () => {
    try {
      if (typeof props.message.onProceed === "function") {
        await props.message
          .onProceed(props.message?.data, props.message?.data?._id)
          .then((status) => {
            (status ?? true) && props.closeMessage();
          });
      } else {
        props.closeMessage();
      }
    } catch (error) {
      console.error("Error in onProceed:", error);
    }
  };

  const { t } = useTranslation();

  return (
    <Overlay
      className={`${props.showMessage ? "" : "hidden"} ${
        props.message.type === 1 ? "notification" : "confirmation"
      }`}
    >
      <Page
        className={props.message.type === 1 ? "notification" : "confirmation"}
      >
        <Content
          className={`${
            props.message.type === 1 ? "notification" : "confirmation"
          } ${props.message.icon ?? "info"}`}
        >
          {props.message.type === 1 && (
            <GetIcon rotate={true} icon={props.message.icon ?? "info"}></GetIcon>
          )}
          <div
            dangerouslySetInnerHTML={{ __html: props.message.content }}
          ></div>
        </Content>
        {props.message.type === 2 ? (
          <Footer>
            <FormInput
              type="close"
              value={
                props.message.type === 2
                  ? props.message.okay
                    ? props.message.okay
                    : t("cancel")
                  : props.message.okay
                  ? props.message.okay
                  : "Okay"
              }
              onChange={closeModal}
            />
            <FormInput
              type="submit"
              name="submit"
              value={props.message.proceed ? props.message.proceed : "Proceed"}
              onChange={proceedAction}
            />
          </Footer>
        ) : (
          <Footer
            className={
              props.message.type === 1 ? "notification" : "confirmation"
            }
          >
            <FormInput
              type="submit"
              name="submit"
              value={props.message.okay ? props.message.okay : "Okay"}
              onChange={closeModal}
            />
          </Footer>
        )}
      </Page>
    </Overlay>
  );
};

export default Message;
