import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { clearLoginSession, fetchLogin } from "../../../store/actions/login";
import { GoogleLogin } from "@react-oauth/google";
import AutoCarousel from "./carousel";
import AutoForm from "../../core/autoform/AutoForm";
import { projectSettings } from "../../project/brand/project";
import { logo } from "../../../images";
import login from "./login.svg";
import withLayout from "../layout";

const Login = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login);
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    document.title = `EventHex System`;
  }, []);

  const formInput = [
    {
      type: "text",
      placeholder: "Enter your email",
      name: "email",
      validation: "email",
      default: "",
      label: "Email",
      minimum: 5,
      maximum: 40,
      required: true,
      icon: "email",
      add: true,
    },
    {
      type: "password",
      placeholder: "Enter your password",
      name: "password",
      // validation: "password",
      info: "At least one uppercase letter (A-Z) \n At least one lowercase letter (a-z) \n At least one digit (0-9) \n At least one special character (@, $, !, %, *, ?, &) \n Minimum length of 8 characters",
      default: "",
      label: "Password",
      minimum: 0,
      maximum: 16,
      required: true,
      icon: "password",
      add: true,
    },
  ];

  useEffect(() => {
    if (user.data?.token) {
      navigate(user.data?.menu[0]?.path ?? "dashboard");
    }
    props.setLoaderBox(user.isLoading);
    if (user.error !== null) {
      props.setMessage({ type: 1, content: t(user.error), proceed: "Okay", icon: "error" });
      dispatch(clearLoginSession());
    }
  }, [user, navigate, props, t, dispatch]);

  const submitChange = async (post) => {
    dispatch(fetchLogin(post));
  };

  const onGoogleSuccess = async (data) => {
    if (data.credential) {
      dispatch(fetchLogin({ authenticationType: "google", credential: data.credential }));
    }
  };

  const CardData = [
    {
      img: "https://eventhex.ai/wp-content/uploads/2024/09/All-in-one-event-management.mp4",
      Content: "All-in-One Event Management",
      Subcontent: "Integrate planning, registration, marketing, and attendee engagement into a single platform, simplifying workflow and reducing event complexity.",
    },
    {
      img: "https://eventhex.ai/wp-content/uploads/2024/09/Centralized-Data-Control.mp4",
      Content: "Centralized Data Control",
      Subcontent: "Unify all event data in one place, eliminating inconsistencies, preventing data loss, and enabling comprehensive reporting and analytics.",
    },
    {
      img: "https://eventhex.ai/wp-content/uploads/2024/09/ENGAGEMENT.mp4",
      Content: "Attendee Engagement Boost",
      Subcontent: "Capture insights and maintain attendee engagement during and after events using our session interaction tools, enhancing satisfaction and effectively retargeting participants for future events.",
    },
  ];

  return (
    <div className="flex min-h-screen relative">
      {/* Left section - Full width on mobile */}
      <div className="w-full lg:w-[40%] p-4 lg:p-8 bg-bg-white">
        <div className="max-w-xl mx-auto">
          {/* Logo */}
          <div className="mb-8 lg:mb-16 flex justify-between">
            <img src={logo} alt="Eventhex Logo" className="h-8 lg:h-10" />
            <div className="flex items-center gap-2">
              <span className="text-text-sub text-sm">Don't have an account?</span>
              <a href="/sign-up"  className="px-4 py-1.5 text-sm text-primary-base hover:text-primary-dark border border-stroke-soft rounded-lg hover:bg-bg-weak transition-colors duration-200">Register</a>
            </div>
          </div>
          {/* Login Form Section */}
          <div>
            <div className="mb-8 text-center">
              <div className="flex items-center justify-center w-12 h-12 bg-bg-weak rounded-full mx-auto mb-6">
                <img src={login} alt="user" className="w-15 h-15" />
              </div>
              <h1 className="text-2xl font-semibold text-text-main mb-2">Login to your account</h1>
            </div>
            <div className="w-[392px]  flex justify-center m-auto center">
              <AutoForm useCaptcha={false} formType="post" formInput={formInput} submitHandler={submitChange} button={t("validate")} isOpen={true} css="plain embed head-hide landing" plainForm={true} customClass="embed" />
            </div>

            {/* OR Divider */}
            <div className="relative w-full text-center my-5">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-stroke-soft"></div>
              </div>
              <div className="relative">
                <span className="px-4 text-sm text-text-sub bg-white">or</span>
              </div>
            </div>

            {/* Google Login */}
            <div className="flex justify-center mb-8">
              <GoogleLogin key="isLoginForm" onSuccess={onGoogleSuccess} onError={() => console.log("Login Failed")} useOneTap theme="outline" shape="rectangular" logo_alignment="center" />
            </div>
          </div>

          {/* Footer */}
          <div className="mt-8 lg:mt-10 pb-4">
            <p className="text-text-sub text-sm text-center lg:text-left">
              ©{currentYear} {projectSettings.title}
            </p>
          </div>
        </div>
      </div>

      {/* Right section - Hidden on mobile, visible on desktop */}
      <div className="hidden lg:block lg:w-[60%] lg:fixed lg:top-0 lg:right-0 lg:bottom-0 lg:p-8 bg-white">
        <div className="h-full flex items-center justify-center">
          <AutoCarousel Data={CardData} />
        </div>
      </div>
    </div>
  );
};

export default withLayout(Login);
